import { useAppStore } from '@/storage/app'
import { BroadcastChannel } from 'broadcast-channel'
import config from '@/config'

export function useSessionTerminationBroadcast() {
  const channel = new BroadcastChannel('app-loaded-at')

  initialiseTerminationListener(channel)
  sendHangupSignal(channel)
}

/**
 * this will send a signal to terminate any other open tabs
 */
function sendHangupSignal(channel: BroadcastChannel): void {
  channel.postMessage(useAppStore().loadedAt)
}

/**
 * this will listen to a signal to terminate this instance
 */
function initialiseTerminationListener(channel: BroadcastChannel): void {
  const appStore = useAppStore()

  channel.addEventListener('message', (newTabLoadedAt: number) => {
    if (appStore.loadedAt === null || newTabLoadedAt > appStore.loadedAt) {
      window.location.replace(config.redirects.sessionExpired)
    }
  })
}
